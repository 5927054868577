

/* landing page css start */

.landing-header {
	background: #ffffff;
	box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.07);
	padding: 24.5px 0px;
	position: relative;
	z-index: 999;
}
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus {
    color: #ffffff;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #09101D;
	font-family: 'Proxima-Nova-Regular' !important;
}


.landing-logo img {
	height: 52px;
	margin-top: 0px;
}
.landing-header .custom-con-padding .row {
    align-items: center;
}
.landing-fields {
	float: right;
}

.landing-fields ul {
	list-style: none;
	padding-left: 0px;
  margin-bottom: 0px;
}

.landing-fields ul li {
	display: inline-block;
	padding: 0px 5px;
}
.landing-fields ul li button#submit {
    padding: 13px 44px !important;
    background: #ED1B24;
    border-radius: 21.5px;
    height: 44px;
    width: auto;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
}
.landing-fields input {
    background: #FFFFFF;
    font-family: Proxima-Nova-Regular;
    height: 45px;
    width: 250px;
    margin-bottom: 0 !important;
    border: 1px solid #858C94;
    border-radius: 8px;
    padding: 12px 16px;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #09101D;
}
.landing-fields input::placeholder{
	color: #09101D;
	opacity: .5;
}
.sl-box-popup-signup select {
	background: #FFFFFF;
	border-radius: 3px;
	font-family: Proxima-Nova-Regular;
	font-size: 12px;
	color: #CCC;
	line-height: 20px;
	height: 45px;
	width: 100%;
	margin-bottom: 0 !important;
}
.login-wrapper{
	background-image: url('/login-bg.png');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	height: calc(100vh - 102px);
    width: 100%;
}
.landing-fp {
	position: absolute;
	font-family: Proxima-Nova-Regular;
	font-size: 10px;
	color: #FFFFFF;
	line-height: 20px;
}
.whitebox {
    width: 536px;
    height: 502px;
    background: #FFFFFF;
    box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
	border-bottom: 8px solid #ED1B24;
    margin: 0 0 0 auto;
}

.login-wrapper .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 90% !important;
    margin: 0 auto;
}


.landing-fp:hover {
  color: #fff;
}

.btn.landing-login-btn {
	background: #78FFD1;
	border-radius: 22.5px;
	width: 87px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: Proxima-Nova-bold;
	font-size: 14px;
	color: #013469;
	letter-spacing: -0.27px;
}

.signup-landing-link {
	font-family: Proxima-Nova-Bold;
	font-size: 14px;
	color: #FFFFFF;
	letter-spacing: -0.27px;
	white-space: nowrap;
}

.signup-landing-link:hover {
  color: #fff;
}

.review-box-landing {
	background: #FFFFFF;
	border: 1px solid #EDEDED;
	border-radius: 6px;
	padding: 20px;
	/* width: 220px; */
	height: 15.5vh;
	margin: 10px 3px;
	cursor: pointer;
	display: flex !important;
	align-items: center;
}

.reviewer-img img {
	height: 40px;
	width: 40px !important;
	object-fit: cover;
	object-position: center;
	border-radius: 100px;
}

.review-box-landing h5 {
	font-family: Proxima-Nova-Bold;
	font-size: 14px;
	color: #003269;
	letter-spacing: -0.27px;
	margin-bottom: 2px;
	width: 135px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
.review-box-landing h6 {
	font-family: Proxima-Nova-Regular;
	font-size: 12px;
	color: #003269;
	letter-spacing: -0.22px;
	margin-bottom: 5px;
	width: 140px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.review-box-landing p {
	font-family: Proxima-Nova-Regular;
	font-size: 12px;
	color: #000000;
	letter-spacing: -0.2px;
  margin-bottom: 0px;
}

.blue-reviewer-box {
  background: #4BA8D5 !important;
  color: #fff !important;
}

.review-box-landing.blue-reviewer-box  h5 {
  color: #fff !important;
}

.review-box-landing.blue-reviewer-box  h6 {
  color: #fff !important;
}

.review-box-landing.blue-reviewer-box  p {
  color: #fff !important;
}


.yellow-reviewer-box {
  background: #F2DD51 !important;
}


.review-box-landing.yellow-reviewer-box  h5 {
  color: #000 !important;
}

.review-box-landing.yellow-reviewer-box h6 {
  color: #000 !important;
}

.review-box-landing.yellow-reviewer-box  p {
  color: #000 !important;
}

.green-reviwer-box {
  background: #80C095 !important;
}


.review-box-landing.green-reviwer-box  h5 {
  color: #000 !important;
}

.review-box-landing.green-reviwer-box h6 {
  color: #000 !important;
}

.review-box-landing.green-reviwer-box  p {
  color: #000 !important;
}

.red-reviewer-box {
  background: #E16652 !important;
}


.review-box-landing.red-reviewer-box  h5 {
  color: #fff !important;
}

.review-box-landing.red-reviewer-box h6 {
  color: #fff !important;
}

.review-box-landing.red-reviewer-box  p {
  color: #fff !important;
}


.purple-reviewer-box {
  background: #A887DF !important;
}

.review-box-landing.purple-reviewer-box  h5 {
  color: #fff !important;
}

.review-box-landing.purple-reviewer-box h6 {
  color: #fff !important;
}

.review-box-landing.purple-reviewer-box p {
  color: #fff !important;
}

.landing-reviews-wrapper {
	padding-top: 15px;
	height: 100%;
	position: relative;
	display: flex;
	align-items: center;

}

.reviews-bg-owl {
  opacity: 01;
  z-index: 0;
}

.landing-demo-account-wrapper {
	height: 100%;
	position: absolute;
	top: 0px;
	right: 5%;
	width: 438px;
  display: flex;
  align-items: center;
  z-index: 999;
}

.landing-demo-account-box {
	background: #FFFFFF;
	box-shadow: 0 0 32px 0 rgba(0,0,0,0.26);
	border-radius: 24px;
	padding: 40px 35px;
	width: 438px;
	float: right;
	margin-top: -70px;
}

.landing-demo-account-box h5 {
	font-family: Proxima-Nova-Bold;
	font-size: 16px;
	color: #013469;
	margin-bottom: 12px;
}

.landing-demo-account-box h1 {
	font-family: Proxima-Nova-Extra-Bold;
	font-size: 38px;
	color: #013469;
	line-height: 45px;
	letter-spacing: 0.2px;
  margin-bottom: 5px;
}

.landing-demo-account-box h6 {
	font-family: Proxima-Nova-Regular;
	font-size: 16px;
	color: #013469;
  margin-bottom: 5px;
}

.landing-highlights {
  padding-top: 20px;
}
.landing-icon-box {

	border-radius: 10px;
	height: 36px;
	width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-pink-box{
  background: #FC3882;
}

.landing-purple-box {
  background: #3D6EFE;
}

.landing-green-box {
  background: #4FD225;
}


.landing-icon-box img {
  filter: brightness(0) invert(1);
  height: 20px;
}
.landing-highlights h5 {
	font-family: Proxima-Nova-Bold;
	font-size: 14px;
	color: #00B3F6 !important;
	letter-spacing: -0.27px;
	margin-bottom: 0px;
	line-height: 5px;
	padding-top: 9px;
}

.landing-highlights small {
	font-family: Proxima-Nova-Regular;
	font-size: 12px;
	color: #000000;
	letter-spacing: -0.25px;
}

.landing-signup-full-btn button {
	background: #78FFD1;
	border-radius: 22.5px;
	width: 100%;
	font-family: Proxima-Nova-Bold;
	font-size: 14px;
	color: #013469;
	letter-spacing: -0.27px;
	padding: 13px;
}

.landing-offer-mobile-wrapper ul {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;

}

.landing-offer-mobile-wrapper ul li {
  display: inline-block;
}

.landing-offer-mobile-wrapper ul li h5 {
  font-family: Proxima-Nova-Bold;
  font-size: 12px;
  color:#00B3F6 !important;
  letter-spacing: -0.27px;
  margin-bottom: 0px;
  padding-top: 9px;
}

.landing-offer-mobile-wrapper {
	background: #FFFFFF;
	box-shadow: 0 0 32px 0 rgba(0,0,0,0.26);
	border-radius: 18px;
	padding: 20px;
  margin: 0px 15px;
  position: absolute;
  bottom: 3%;
  width: 93%;
  display: none;
}


/* @media only screen and (min-width: 768px) and (max-width: 1210px) {

  .review-box-landing {
  	padding: 10px;
  }
  .review-box-landing h5 {
	white-space: nowrap;
  font-size: 13px;
}
.review-box-landing h6 {
	white-space: nowrap;
  font-size: 11px;
}

.reviewer-img img {
	height: 30px;
	width: 30px !important;
}

.review-box-landing p {
	font-size: 10px;
}
} */


@media only screen and (min-width: 768px) and (max-width: 992px) {

  .landing-fields input {
	   width: 130px;
}

}

@media only screen and (min-width: 250px) and (max-width: 768px) {

  .landing-fields input {
	  display: none;
}
.landing-login-modal-mobile .landing-fp {
	display: block !important;
	color: #013469 !important;
	position: relative;
	font-family: Proxima-Nova-Bold;
font-size: 14px;
	margin-top: 10px !important;
}

.landing-fp {
	display: none !important;
}

.landing-bg-gradient {
  display: none;
}

.landing-demo-account-wrapper {
  left: 0px;
  right: 0px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-top: 25px;
}

.btn.landing-login-btn {
	background: transparent;
	border-radius: 0;
	width: auto;
	height: auto;
	font-size: 14px;
	color: #fff;
}

.landing-login-modal-mobile .btn.landing-login-btn {
	background:#ED1B24;
    border-radius: 22.5px;
    width: 100%;
    font-family: Proxima-Nova-Bold;
    font-size: 14px;
    // color: #013469;
    letter-spacing: -0.27px;
	padding: 13px;
	margin-top: 10px;
}

.landing-fields ul li {
	padding: 0px 0px;
}
.landing-logo img {
	height: 50px;
	margin-top: -10px;
	max-width: 100%;
	object-fit: contain;
}

.landing-demo-account-box {
	width: auto;
	float: unset;
}
.landing-highlights {
	display: none;
}
.landing-demo-account-wrapper {
	// display:block;
	// margin-top: 25px;
}
.landing-icon-box {
	margin: 0 auto;
}
.landing-offer-mobile-wrapper ul{
  width: 100%;
  text-align: center;
}
.landing-offer-mobile-wrapper ul li {
	display: inline-block;
	width: 32%;
	text-align: center;
  border-right: 1px solid #eee;
}
.landing-offer-mobile-wrapper ul li:last-child {
  border-right: unset;
}
.landing-offer-mobile-wrapper {
  display: block;
}

}

@media only screen and (min-width: 992px) and (max-width: 1060px) {
  .custom-con-padding {
    padding: 0 3.5% !important;
  }
}

@media only screen and (min-width: 250px) and (max-width: 400px) {

  .review-box-landing {
  	padding: 10px;
  }

  .reviewer-img img {
	height: 35px;
	width: 35px !important;
}
.review-box-landing h5 {
	font-size: 13px;
}
.review-box-landing h6 {
	font-size: 11px;
}

}


.landing-login-modal-mobile .modal-content {
  padding: 15px 20px;
  background:#FFFFFF;
  border-radius: 16px;
  height: 100vh;
  overflow: hidden;

 }

 .landing-popup-logo {
 	text-align: center;
 }

 .landing-popup-logo img {
	height: 40px;
}

.landing-popup-fields h4 {
  font-family: Proxima-Nova-Bold;
  font-size: 20px;
  color: #013469;
}

.landing-popup-fields p {
  font-family: Proxima-Nova-Regular;
  font-size: 12px;
  color: #555555;
  letter-spacing: -0.2px;
  text-align: right;
  margin-bottom: 0px;
}

.landing-popup-fields h6 {
	font-family: Proxima-Nova-bold;
	font-size: 18px;
	color: #013469;
	text-align: right;
  cursor: pointer;
}

.landing-popup-fields {
  padding-top: 35px;
}

.close-landing-popup a {
	text-align: center;
	width: 100%;
	display: flex;
	justify-content: center;
	position: absolute;
	bottom: 2.5%;
	font-family: Proxima-Nova-Regular;
	font-size: 14px;
	color: #555555;
	letter-spacing: 0;
	margin-left: -10px;
  text-transform: uppercase;
}

.btn.focus, .btn:focus {
	outline: 0;
	box-shadow: unset;
}

.landing-hover-show-profile {
	// position: absolute;
	opacity: 1;
	box-shadow: 0 0 15px 0 rgba(0,0,0,0.50);
	border-radius: 10px;
	z-index: 99999;
  	height: auto !important;
  /* display: block !important; */
	display: block !important;
	left: 0;
	margin-top:-10px;
	position: fixed;
    left: 0;
    right: 0;
    display: block;
    width: 40% !important;
	margin: 0 auto !important;
	// .upperdiv {
	// 	width: 280px;
	// }
}

.custom-con-padding {
  padding: 0 6.2% ;
}


.landing-bg-gradient {
	background-image: linear-gradient(90deg, rgba(255,255,255,0.10) 0%, rgba(255,255,255,0.80) 100%);
	position: absolute;
	height: 100%;
	width: 538px;
	top: 0;
	right: 0;
	bottom: 0px;
	z-index: 99;
}

// .landing-reviews-data:hover + .landing-hover-show-profile {
//   display: block !important;
// }

.owl-item.active .item {
    position: relative;
}



/* 30 July css start */


.signup-form input {
	height: 45px;
}

.landing-demo-account-box h3 {
	font-family: Proxima-Nova-Bold;
	font-size: 22px;
	color: #013469;
}

.landing-demo-account-box .form-group {
	margin-bottom: 25px;
}

.landing-terms-service a {
  color: #013469;
  text-decoration: underline;
}

.landing-terms-service label {
  color: #707070;
  font-size: 14px;
}

.landing-signup-course {
  display: block;
}

.landing-terms-service li {
	margin-bottom: 10px;
}

.landing-signup-box {
  min-height: 540px;
  position: relative;
}

.landing-signup-box .btn {

  position: absolute;
  width: 85%;
  bottom: 8%;

  }
.landing-signup-course h3 {
  font-family: Proxima-Nova-Bold;
  font-size: 22px;
  color: #013469;
}

#landing-password-modal-mobile .modal-content {
	height: auto !important;
}



.incorrect-warning {
	font-family: Proxima-Nova-Regular;
	font-size: 12px;
	color: #FF0000 !important;
	text-align: left !important;
	margin-top: 0px;
	margin-bottom: 10px !important;
}

.forgotton-password-popup {
	font-family: Proxima-Nova-Regular;
	font-size: 12px;
	color: #013469;
	text-align: center;
	width: 100%;
	display: block;
	padding-top: 25px;
}

.user-not-found-error p {
	background: #EEF6FC;
	border-radius: 3px;
	font-family: Proxima-Nova-Regular;
	font-size: 12px;
	color: #FF0000;
	padding: 6px 10px;
	// position: absolute;
	// top: 3px;
	// left: 50px;
}

@media only screen and (min-width: 250px) and (max-width: 768px) {

  .landing-signup-box {
    display: none;
  }

  .user-not-found-error {
    display: none;
  }

  .landing-terms-service {
	width: 100%;
	float: left;
	padding-top: 15px;
	padding-bottom: 10px;
}

}


// july 31
.landing-signup-full-btn {
	width: 100%;
	clear: both;
}
.error-border-red {
	border: 1px solid #FF0000 !important;
}

// Aug 5
.custom_popup.fp-pas-modal.h-100vh {
    overflow: scroll;
}

/* 6 August css */

.bg-testi-wrapper {
	list-style: none;
  padding-left: 0px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  h5, h6, p {
	  text-align: left;
  }
}

.bg-testi-wrapper li {
	display: inline-block;
	width: 16.4%;
}

// .review-box-landing {
// 	margin: 10px 3px !important;
// }


@media only screen and (min-width: 1240px) and (max-width: 1320px) { 

  .bg-testi-wrapper li {
    width: 16.3%;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1240px) { 

  .bg-testi-wrapper li {
    width: 19.6%;
  }
}

@media only screen and (min-width: 850px) and (max-width: 1100px) { 

  .bg-testi-wrapper li {
    width: 24%;
  }
}

@media only screen and (min-width: 650px) and (max-width: 849px) { 

  .bg-testi-wrapper li {
    width: 32%;
  }
}

@media only screen and (min-width: 250px) and (max-width: 649px) { 

  .bg-testi-wrapper li {
    width: 49%;
  }
}

@media only screen and (min-width: 330px) and (max-width: 500px) {

  .review-box-landing {
  	padding: 10px;
  }
}

@media only screen and (min-width: 250px) and (max-width: 369px) { 

  .review-box-landing {
    padding: 0px;
  }
}

.landing-bg-gradient {
	top: 100px !important;
}

.landing-demo-account-wrapper {
	top: 85px !important;
}

.overflow-hidden {
  overflow: hidden;
}

@media only screen and (min-width: 250px) and (max-width: 768px) {  

  .landing-demo-account-wrapper {
    top: 90px !important;
  }

  .landing-offer-mobile-wrapper {
    bottom: 22% !important;
  }

}

// .landing-hover-show-profile {
//   width: 300px !important;
// }

.show-later {
  display: none !important;
}

.landing-reviews-wrapper {
	 position: unset !important; 
}




.testi-hide-show-pl {
  position: relative !important;
  height: 15.5vh;
  margin-bottom: 7px;
}

.testi-hide-show-pl-box {
  position: absolute;
  left: 0px;
  height: 15.5vh;
  top: -10px;
}

.testi-details-wrapper {
    position: fixed;
    z-index: 99999;
    background: rgba(0,0,0,0.6);
    height: 100vh;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    z-index: 99999;
    align-items: center;
}

img.testi-details-close {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 30px;
}



@media only screen and (min-width: 1000px) and (max-width: 1366px) {   

	.review-box-landing h5 {
		width: 100px;
	}
	.review-box-landing h6 {
		width: 100px;
	}

}



@media only screen and (min-width: 250px) and (max-width: 420px) {   

	.review-box-landing h5 {
		width: 80px;
	}
	.review-box-landing h6 {
		width: 80px;
	}

}



.landing-hover-show-profile.review-box-landing h6 {
	width: auto;
	white-space: normal;
}

@media only screen and (min-width: 992px) and (max-width: 1366px) {    

	.landing-fields input {
		width: 250px;
	}

}

.landing-fields ul {
	display: flex;
	align-items: flex-start;
}

.user-not-found-error {
	position: relative;
	top: 0px;
	width: 100%;
	max-width: 275px;
}
.signup-landing-link {
	margin-top: 6px;
}

@media (max-width: 991px){
	.whitebox {
		width: 100%;
		height: 560px;
		margin: 0 auto 0 auto;
	}
}
@media only screen and (min-width: 250px) and (max-width: 768px) {    
	.custom-con-padding {
		padding: 0 2%;
	}
	.landing-fields ul {
		display: block;
	}
	.landing-login-modal-mobile {
		max-width: 400px;
		margin: 0 auto;
	}
	

}

@media only screen and (min-width: 400px) and (max-width: 768px) {    

	.landing-login-modal-mobile .modal-content {
		height: 100% !important;
	}

	.custom_popup .modal-dialog {
		height: 90vh;
	}
}

.monthly-cal-picker {
	.react-datepicker__day--highlighted-custom-past {
		// color: #28a745
		color: white;
		border-radius: 50%;
		background:#28a745;
	}
	.react-datepicker__day--highlighted-custom-future {
		color: white;
		border-radius: 50%;
		background: #ffc107;
	}
}

.monthly-cal-picker {
	.react-datepicker__day--highlighted-custom-3 {
		background: #f40820;
    	border-radius: 50%;	
	}
	.react-datepicker__day--highlighted-custom-4 {
		color: white;
		border-radius: 50%;
		background: red;

		// color: white;
	}
}

.download-upcoming-class {
	height: 18px;
    float: right;
	margin-top: 4px;
	cursor: pointer;
}


// 11 August Css
.landing-terms-service .custom-checkbox .custom-control-label::before {
	margin-top: -3px !important;
}

.landing-hover-show-profile .linkd-icon {
	margin-top: 8px;
}

@media only screen and (min-width: 250px) and (max-width: 768px) { 

	.landing-hover-show-profile {
		width: 75% !important;
	}

	.landing-demo-account-box {
		margin-top: 0px;
	}

}




// 13 August css

.cnt-signin {
	text-align: center;
	width: 100%;
	display: block;
	padding-top: 15px;
	font-size: 14px;
	color: #013469;
	font-family: Proxima-Nova-Bold;
}

.cnt-signin:hover {
	color: #00b4f3;
}

.landing-hover-show-profile.review-box-landing p {
	text-align: justify;
	font-size: 14px;
	padding-top: 5px;
	padding-bottom: 5px;
}