// Styles
// CoreUI Icons Set
@import '~@coreui/icons/css/coreui-icons.css';
// Import Flag Icons Set
@import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
@import './scss/style.scss';

:root {
  --themePrimaryColor: #ED1B24;
  --themePrimaryColorFocus: #c00d16;
}

.form-modal-add-div {
  height: 25px;
  width: 200px;
  border: 1px solid black;
  border-radius: 10px;
  padding-left: 10px;
}

.column {
  margin: 20px;
  width: 250px;
}

.price {
  color: grey;
  font-size: 22px;
}

.sub-menu .sub-menu {
  margin-left: 20px;
}

.card button {
  border: none;
  outline: 0;
  padding: 12px;
  color: white;
  background-color: #f40820;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
  margin-top: 10px;
}

.card button:hover {
  opacity: 0.7;
}

.flex-container {
  display: flex;
}

.selected-package {
  background-color: #f40820 !important;
  color: #fff;
}

.selected-package * {
  color: #fff !important;
}

.react-calendar {
  width: 100% !important;
  border: none !important;
  padding: 25px !important;
}

.react-calendar__tile--active {
  background: #f40820 !important;
  border-radius: 5px !important;
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu ul a {
  padding: 10px;
  color: #333;
  display: block;
  cursor: pointer;
}

.dropdown-menu ul a:hover {
  background: #eee;
}

.profile-wrapper {
  background: #fff;
}

.profile-navigator {
  padding: 15px;
}

.profile-tab-box .history-tab-table-wrapper {
  padding: 0;
}

.profile-tab-box {
  padding: 0px 25px 25px 25px;
}

.btn-primary {
  background-color: #f40820;
  border-radius: 50px;
  border: none;
}

.btn-primary:hover,
.btn-primary:focus {
  background: #d92638 !important;
  color: #fff !important;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: #f40820;
  border-color: #f40820;
}
.chart-title .btn-allign .week-btn[disabled] {
  opacity: .2;
  cursor: not-allowed;
}
.searchright ul.dropdown-list {
  margin: 0;
  position: absolute;
  left: 0;
  top: 45px;
  background: #ffffff;
  width: 100%;
  border: 1px solid #f3f3f3;
  border-top: 0;
  list-style: none;
  padding: 0;
  z-index: 2;
  min-height :34px;
  max-height: 132px;
  overflow: auto;
  
}
.searchright ul.dropdown-list li {
  display: flex;
  justify-content: space-between;
}

.searchright ul.dropdown-list li {
  padding: 8px 20px;
  border-bottom: 1px solid #f3f3f3;
  background-color: #ffffff;
  cursor: pointer;
  font-size: 14px;
  text-transform: capitalize;
  line-height: 16px;
  color: gray;
}

.searchright ul.dropdown-list li:hover {
  color: #000000;
}
.btn-primary-outline {
  background: transparent;
  border: 1px solid #f40820;
  color: #f40820;
  border-radius: 50px;
}

.btn-primary-outline:hover,
.btn-primary-outline:focus,
.btn-primary-outline:active {
  background: #d92638 !important;
  color: #fff !important;
}

.btn-sm {
  padding: 5px 12px;
}

.week-btn{
  width: 80px !important;
}

.profile-personal-training .toggle-container h5 {
  margin: 0;
  display: flex;
  font-size: 20px;
  align-items: center;
  justify-content: flex-end;
}

.profile-personal-training .toggle-container h5 .toggle-switch-main {
  margin-left: 15px;
}

.profile-personal-training .toggle-date-view p {
  font-size: 15px;
  margin: 5px 0;
  font-weight: 600;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.profile-personal-training .toggle-date-view p a {
  margin-left: 10px;
  margin-top: -5px;
}

.profile-personal-training .toggle-date-picker {
  margin-bottom: 6px;
}

.profile-personal-training .toggle-actions {
  display: flex;
  justify-content: flex-end;
}

#app-main-loader {
  position: fixed;
  z-index: 999999999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}

#app-main-loader img {
  height: 120px;
  width: auto;
}

#app-main-loader:not(.show) {
  display: none;
}

.profile-wrapper .btn:hover {
  color: #00b4f3 !important;
}

.full-width-calendar .rdrCalendarWrapper {
  width: 100%;
}

.full-width-calendar .rdrCalendarWrapper .rdrMonths {
  width: 100%;
}

.full-width-calendar .rdrCalendarWrapper .rdrMonths .rdrMonth {
  width: 100%;
}

.workoutplane-tab-content .weakdaysbar {
  justify-content: center;
}

.workoutplane-tab-content .weakdaysbar li {
  margin: 0 7.5px;
}

.workoutplane-tab-content .weakdaysbar li a {
  display: block;
  text-align: center;
  width: 100%;
}

.assign-exercise {
  margin: 10px 0;
}

.exercise-search-container {
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  top: 40px;
  max-height: 200px;
  overflow-y: auto;
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
}

.exercise-search-container .assign-exercise:hover {
  background: #eee;
}

.exercise-search-container .assign-exercise {
  margin: 0;
  padding: 7px 10px;
  display: block;
  cursor: pointer;
}

#configure-exercise-modal .set-tabs {
  justify-content: center;
}

#configure-exercise-modal h4 {
  font-size: 25px;
}

.form-group.fadded {
  opacity: 0.4;
}

.workoutplane-tab-content .weakdaysbar li a {
  cursor: pointer;
}

.copy-to-days-dropdown {
  padding: 0 !important;
  margin: 0 !important;
}

.copy-to-days-dropdown li {
  margin: 0 !important;
}

.copy-to-days-dropdown li a {
  cursor: pointer;
  display: block;
  width: 100%;
  text-align: left;
  font-size: 15px;
  padding: 8px 10px;
}

.profile-tabs-actioner {
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid #ccc;
  text-align: right;
}

.card-search-top {
  padding: 25px 25px 0;
}

.downloadLink {
  opacity: 0;
}

.payment-member-tabs {
  position: relative;
}

.payment-member-tabs .btn-head {
  position: absolute;
  right: 25px;
  top: 20px;
}

.same-input-qty-field-items {
  display: flex;
  align-items: center;
}

.same-input-qty-field-items button {
  margin: 0;
  padding: 0 4px;
  background: transparent;
  border: none;
}

.same-input-qty-field-items button:hover {
  background-color: #eee;
}

.same-input-qty-field-items button:hover,
.same-input-qty-field-items button:focus {
  outline: none;
  border: none;
}

.mt-5px {
  margin-top: 5px;
}

.mt-10px {
  margin-top: 10px;
}

.mt-15px {
  margin-top: 15px;
}

.mt-25px {
  margin-top: 25px;
}

.ml-5px {
  margin-left: 5px;
}

.ml-10px {
  margin-left: 10px;
}

.ml-15px {
  margin-left: 15px;
}

.ml-25px {
  margin-left: 25px;
}

.mr-5px {
  margin-right: 5px;
}

.mr-10px {
  margin-right: 10px;
}

.mr-15px {
  margin-right: 15px;
}

.mr-25px {
  margin-right: 25px;
}

.mb-5px {
  margin-bottom: 5px;
}

.mb-10px {
  margin-bottom: 10px;
}

.mb-15px {
  margin-bottom: 15px;
}

.mb-25px {
  margin-bottom: 25px;
}

.video-modal-head {
  float: unset;
}

.side-menu-icon {
  display: none;
}

.same-line-label {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
}

.same-line-label label {
  padding-left: 16px;
  font-size: 16px;
  white-space: nowrap;
}

.freeze-label {
  padding-left: 0px !important;
  font-size: 12px !important;
  white-space: nowrap;
  font-weight: 600 !important;
}

.same-line-label div {
  text-align: center;
}

.same-input-qty-field-items button {
  background: #eee;
  color: #000;
  font-size: 24px;
  width: 25px;
}

.same-input-qty-field-items {
  overflow: hidden;
  border-radius: 5px;
}

.same-input-qty-field-items input {
  text-align: center;
  max-width: 90px;
  border: none !important;
}

.same-input-qty-field-items {
  border: 1px solid #ccc;
  width: auto !important;
  margin: 0 auto;
  max-width: 140px;
}

.qtys-con {
  display: flex;
  margin-left: 25px;
}

.react-select-container .react-select__control {
  border-color: #ff00bb; 
  height: 10px;
}



.parentSize {
  font-size: 12px;
  font-weight: bold;
}

.filterSize {
  font-size: 13px;
  font-weight: bold;
}

.addLabel {
  font-size: 12px;
  color: #007BFF;
}

.qtys-con .form-modal-div.same-input-qty-field {
  margin-left: 35px;
}

.switch-field-form {
  display: flex;
  align-items: center;
}

.switch-field-form label {
  margin-bottom: 0 !important;
}

.silentformtitle {
  color: #777;
  font-size: 15px;
  font-weight: 500;
}

.tab-title-item {
  font-size: 20px;
  font-weight: 800;
  color: #ED1B24;
  font-family: Proxima-Nova-Bold;
  margin-bottom: 15px;
}

.form-tab-box-sticker {
  position: sticky;
  top: 15px;
  height: auto;
}

.form-tab-box .form-modal-div {
  margin-bottom: 10px;
}

.cam-file-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.cam-file-container .cam-conatiner {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
}

.cam-file-actions {
  position: absolute;
  bottom: 50px;
  text-align: center;
  display: block;
  width: 100%;
  left: 0;
}

.cam-capture-image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.cam-file-actions-btn {
  border: none;
  display: inline-block;
}

.btn-styl-inverse {
  background: #fff;
  color: #333 !important;
}

.file-input-picker-cont {
  display: flex;
  align-items: center;
  border: 1px solid #858C94;
  border-radius: 8px;
  padding-left: 10px;
  overflow: hidden;
}

.file-input-picker .btnUpload {
  margin: 0;
  border-radius: 0;
  width: 35px;
  border: none;
  border-right: 1px solid #858C94;
}

.file-input-picker .btnUpload:last-child {
  border: none;
}

.file-input-picker .file-input-name {
  flex-grow: 1;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.autorizeTerms label {
  font-size: 15px;
}

.autorizeTerms span.checkmark {
  height: 21px;
  width: 21px;
}


.profile-basic-side-details {
  text-align: center;
}

.profile-basic-side-details h5 {
  font-size: 14px;
}

.profile-basic-side-details h6 {
  font-size: 24px;
  display: block;
  text-align: center;
}

.profile-basic-side-details h6 span {
  color: #888;
  font-size: 12px;
}

.help-block {
  font-size: 12px;
}

.file-input-picker {
  overflow: hidden;
}

.file-input-picker .file-input-link {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
}

.help-block-hint {
  color: rgb(42, 181, 235) !important;
}

.smcheckbox label {
  font-size: 14px;
}

.smcheckbox span.checkmark {
  width: 15px;
  height: 15px;
}

.smcheckbox span.checkmark::after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 9px;
}

.outline-box {
  border: 1px solid #ccc;
  margin: 10px 0;
  border-radius: 5px;
  padding: 10px;
}

.onform-link {
  text-decoration: underline !important;
  font-size: 14px;
}

.m-0 {
  margin: 0 !important;
}

.text-center {
  text-align: center !important;
}

.less-pad {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.less-pad-l {
  padding-left: 20px !important;
}

.less-pad-r {
  padding-right: 5px !important;
}

.form-top-btns-bar {
  margin-bottom: 10px;
  display: block;
}

.form-top-btns-bar ul {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  width: calc(100% + 10px);
  list-style: none;
  margin-left: -5px;
}

.form-top-btns-bar ul li {
  flex-grow: 1;
  text-align: center;
  padding: 0 5px;
}

.form-top-btns-bar ul li a {
  display: block;
  width: 100%;
  background: #fff;
  color: #000;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.form-top-btns-bar ul li a.active {
  background: #ED1B24;
  color: #fff;
}

.page-item.active .page-link {
  background-color: #ED1B24 !important;
  border-color: #ED1B24 !important;
}

.profile-tab-list-info {
  list-style: none;
  padding: 0;
  margin: 0;
}

.profile-tab-list-info li {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 4px 0;
}

.profile-tab-list-info li span {
  font-size: 14px;
}

.profile-tab-list-info span:last-child {
  margin-right: 0;
  font-weight: 600;
  margin-left: auto;
}


#authLayout,
.login-landing-page {
  min-height: 100vh;
  background: #000;
  background-image: url("/img/login-bg.png");
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

#authLayout::before,
.login-landing-page::before {
  position: absolute;
  content: "";
  z-index: 0;
  background: rgba(0, 0, 0, 0.20);
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.landing-demo-account-box {
  position: relative !important;
  left: auto !important;
  top: auto !important;
  margin-top: 0px !important;
  background: rgba(0, 0, 0, .8);
  width: 500px;
  padding: 25px;
  border-radius: 8px;
}

.auth-content-box {
  background: rgba(0, 0, 0, 0.8);
  width: 500px;
  padding: 25px;
  border-radius: 8px;
}

.auth-content-box ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.auth-logo {
  z-index: 99;
  position: relative;
  width: 170px;
  height: auto;
  margin-bottom: 15px;
}

.auth-content-area {
  text-align: center;
}

.auth-content-area .landing-header {
  background: transparent;
  padding: 0;
  box-shadow: none;
}

.auth-content-box ul li {
  margin: 15px 0;
  text-align: left;
}

.auth-content-box h1,
.landing-demo-account-box h4 {
  font-weight: 500 !important;
  font-size: 30px;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 0px;
  display: block;
  text-align: center;
}

.auth-content-box label,
.login-landing-page label {
  color: #fff;
  font-size: 14px;
  opacity: 1;
}

.auth-content-box input,
.login-landing-page input {
  border-radius: 8px !important;
  padding: 12px 15px !important;
  height: auto !important;
}

.auth-content-box input,
.login-landing-page input {
  background: transparent !important;
  border: 2px solid #fff !important;
  color: #fff !important;
}

.login-landing-page input::placeholder,
.login-landing-page select::placeholder,
.login-landing-page textarea::placeholder,
.login-landing-page input::-ms-input-placeholder,
.login-landing-page select::-ms-input-placeholder,
.login-landing-page textarea::-ms-input-placeholder,
.login-landing-page input::-webkit-input-placeholder,
.login-landing-page select::-webkit-input-placeholder,
.login-landing-page textarea::-webkit-input-placeholder,
.login-landing-page input::-moz-placeholder,
.login-landing-page select::-moz-placeholder,
.login-landing-page textarea::-moz-placeholder {
  color: #fff !important;
  -webkit-text-fill-color: #fff !important;
}

.auth-content-box input:hover,
.auth-content-box input:focus,
.auth-content-box input:-webkit-autofill,
.auth-content-box input:-webkit-autofill:hover,
.auth-content-box input:-webkit-autofill:focus,
.auth-content-box textarea:-webkit-autofill,
.auth-content-box textarea:-webkit-autofill:hover,
.auth-content-box textarea:-webkit-autofill:focus,
.auth-content-box select:-webkit-autofill,
.auth-content-box select:-webkit-autofill:hover,
.auth-content-box select:-webkit-autofill:focus,

.login-landing-page input:hover,
.login-landing-page input:focus,
.login-landing-page input:-webkit-autofill,
.login-landing-page input:-webkit-autofill:hover,
.login-landing-page input:-webkit-autofill:focus,
.login-landing-page textarea:-webkit-autofill,
.login-landing-page textarea:-webkit-autofill:hover,
.login-landing-page textarea:-webkit-autofill:focus,
.login-landing-page select:-webkit-autofill,
.login-landing-page select:-webkit-autofill:hover,
.login-landing-page select:-webkit-autofill:focus {
  background: transparent !important;
  border: 2px solid #fff !important;
  color: #fff !important;
  -webkit-text-fill-color: #fff !important;
}

.login-landing-page .form-group img {
  display: none;
}

.login-landing-page .form-group {
  margin: 15px 0 !important;
}

.auth-btn,
.login-landing-page .btn {
  background: var(--themePrimaryColor) !important;
  border-radius: 50px !important;
  padding: 12px 15px !important;
  width: 100% !important;
  display: block !important;
  color: #fff !important;
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.auth-btn:hover,
.auth-btn:focus,
.login-landing-page .btn:hover,
.login-landing-page .btn:focus {
  background: var(--themePrimaryColorFocus) !important;
  color: #fff !important;
}

.profile-tab-list-info li hr {
  background: #eeee;
  height: 1px;
  width: 100%;
  display: block;
  margin: 0;
  border: none;
}

.profile-tab-list-info li span.title {
  text-align: left;
  font-weight: 800;
  width: 100%;
  color: var(--themePrimaryColor);
}

.profile-tab-list-info li span.silent {
  color: #999;
}

.theme-editor-wrapper {
  border: 1px solid #858C94;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 15px;
}

.theme-editor-wrapper .theme-editor {
  padding: 0 15px;
  min-height: 120px;
}

.file-input-picker .btnUpload {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 65px;
}

.file-input-picker .btnUpload span:first-child {
  // margin-right: 5px;
}

.landing-signup-full-btn a {
  color: #fff !important;
  font-size: 14px;
  font-family: 'Proxima-Nova-Regular' !important;
}

.landing-signup-full-btn {
  text-align: center;
}

.user-not-found-error {
  max-width: unset !important;
}

.auth-info {
  color: #fff !important;
  text-align: center !important;
  font-size: 14px !important;
}

p.incorrect-warning {
  display: block;
  text-align: center !important;
  width: 100%;
  max-width: unset;
}

.rimg-viewerpicker {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rimg-viewerpicker-img {
  height: 250px;
  width: 250px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover !important;
  background: #eee;
  position: relative;
  margin-bottom: 15px;
  border: 1px solid #dedede;
}

.rimg-viewerpicker-actions {
  position: absolute;
  bottom: -15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  left: 0;
}


.upload-receipt {
  position: absolute;
  bottom: 99px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  left: 290px;
}
.upload-receipt-bar button.btnUpload {
  border: none;
  width: auto;
  background: transparent;
  margin: 0;
  padding: 0.15rem !important;
}

.upload-receipt-bar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 4px;
}
tr.expanded-row td {
  background: #ed462f1f;
  border: 1px solid #ed462f;
}
.upload-receipt .btnUpload span:last-child {
  display: none;
}

.rimg-viewerpicker-actions .btnUpload span:last-child {
  display: none;
}


.btnUpload span:last-child {
  display: none;
}

.eyeBtn {
  font-size: 15px !important;
}

.upload-receipt .btnUpload {
  border-radius: 50%;
  width: 37px;
  height: 35px;
  margin: 0 2.5px;
  background: #fff;
  border: 1px solid #dedede;
}

.rimg-viewerpicker-actions .btnUpload {
  border-radius: 50%;
  width: 37px;
  height: 35px;
  margin: 0 2.5px;
  background: #fff;
  border: 1px solid #dedede;
}

.upload-receipt .btnUpload:hover {
  background: #ED1B24;
  color: #fff;
}

.rimg-viewerpicker-actions .btnUpload:hover {
  background: #ED1B24;
  color: #fff;
}

.quill button svg {
  display: inline-block;
}

.radiocustom {
  height: 34px !important;
}

.table th {
  font-size: 14px !important;
}

.table td {
  font-size: 14px !important;
  padding: 14px 10px !important;
  vertical-align: middle;
}

.table-less-size .table td {
  padding: 6.5px 10px !important;
}

.student-listing-wrapper .right-flex-header {
  margin-left: auto !important;
  margin-right: 0 !important;
  justify-content: flex-end !important;
}


.control-size {
  font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    display: flex;
    margin-bottom: 5px;
    align-items: center;
    color: #09101D;
    font-family: 'Proxima-Nova-Regular';
}

.lead-dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.page-link {
  font-size: 13px;
}

td.table-actions i,
td.table-actions a {
  display: inline;
  list-style: none;
}

.outline-box .same-line-label label {
  margin: 0;
  font-size: 14px;
}

.outline-box .same-line-label .same-input-qty-field label {
  text-align: center;
  padding: 0;
  display: block;
  font-size: 12px;
}

.outline-box .same-line-label .same-input-qty-field .same-input-qty-field-items {
  height: 28px;
}

.outline-box .same-line-label .same-input-qty-field {
  margin-left: 10px;
}

.outline-box .same-line-label .qtys-con {
  margin-right: 15px;
  margin-left: auto;
}

.borderBottom {
  border-bottom: 1px solid #f3f3f3  ;
}

header.header-top {
  z-index: 99;
  display: none;
  position: fixed;
  left: 0;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #ccc;
  margin-top: 0;
  align-items: center;
  padding: 10px 15px;
  height: 60px;
}

.sidebar {
  z-index: 98;
}

header.header-top .header-top-right {
  margin-right: 0;
  margin-left: auto;
}

header.header-top .header-top-right a {
  color: #F4071F;
  font-size: 20px;
}

.dashborad-section.menu-open header.header-top .header-top-right a .showMenuIcon {
  display: none;
}

.dashborad-section:not(.menu-open) header.header-top .header-top-right a .hideMenuIcon {
  display: none;
}

.student-listing-wrapper.common-wrapper {
  margin-top: 15px;
}

.react-paginate-wrapper {
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.react-paginate-wrapper ul.pagination-student {
  float: unset;
  margin: 0;
  padding: 0;
}

.small-as-label {
  font-size: 12px;
}

.addCommentBtn {
  background-color: #f2002e;
  color: #ffffff;
  border: 1px solid #f2002e;
  border-radius: 50px;
  cursor: pointer;
  line-height: 22px;
  font-weight: 400;
  height: 34px;
    width: 128px;
}

.video-modal .modal-footer ul li span.close-top,
.video-modal .modal-footer ul li button {
  font-size: 14px;
  padding: 5px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 120px;
}

.video-modal .modal-footer ul li {
  margin-inline: 7.5px;
}
.lighthouse-modal .modal-dialog {
  max-width: 600px;
}
.video-form-inner {}

.lighthouse-modal .modal-dialog .modal-body .video-form-inner {
    height: 400px;
}.flex-header.top-header .btn-common-img {
  margin-left: 0;
}

.lighthouse-modal .modal-dialog .modal-body .video-form-inner img , .lighthouse-modal .modal-dialog .modal-body .video-form-inner video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.lighthouse-modal .modal-dialog .modal-body button.close-top {
    position: absolute;
    right: -19px;
    top: -19px;
    margin: 0;
    font-size: 0;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lighthouse-modal .modal-dialog .modal-body button.close-top svg {fill: black;font-size: 14px;font-weight: normal;}
.lighthouse-modal .modal-dialog .modal-body {
  padding: 0;
}

.lighthouse-modal .modal-dialog .modal-body .video-modal-head {
  margin: 0;
  padding: 20px;
}

.video-modal-head.confirm-modal {}

.lighthouse-modal .modal-dialog .modal-body .video-modal-head h3 {
  font-size: 30px;
  margin: 0;
}
.video-modal .modal-footer ul {
  justify-content: center;
  padding: 0;
}
ul.expand {
  transition: .3s ease;
  height: 0;
  overflow: hidden !important;
  margin-top: 20px !important;
  background: #fff;
  padding: 10px !important;
  box-shadow: 0px 1px 10px #0000000a;
}
ul.expand.active {
  overflow: visible;
  height: auto;
}
ul.upload-img-gallery {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}

ul.upload-img-gallery li {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 5px;
  overflow: hidden;
}

ul.upload-img-gallery li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
}
.expand li {
  margin: 0 0 5px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
}
.expand li:last-child {
  margin: 0;
}
.expand li span.name-text {
  font-size: 16px;
  font-weight: 700;
  color: #5d5b5b;
}

.expand li span.comment-text {
  font-size: 16px;
  line-height: 20px;
}
.video-modal .modal-body .video-form-inner {
  max-height: 74vh;
  overflow-y: auto;
}

.cam-file-actions-btn {
  margin-inline: 10px;
  min-width: 120px;
}

.card {
  padding: 20px !important;
  background: #FFFFFF;
  box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  margin-bottom: 15px;
}

.card-header {
  padding: 0 !important;
  background: transparent !important;
  border: none !important;
}

.card-header strong {
  font-size: 20px !important;
  font-weight: 800 !important;
  color: #ED1B24 !important;
  font-family: Proxima-Nova-Bold !important;
  margin-bottom: 15px !important;
  display: block !important;
}

.card-body {
  padding: 0 !important;
}

.card button {
  width: auto !important;
  font-size: 14px !important;
}

.search-center-box .or-label h4 {
  font-size: 13px;
  margin-bottom: 0;
  margin-top: 15px;
}

.search-center-box .searchright {
  width: 100%;
}

.search-center-box .search-payments-btn {
  margin-top: 15px;
}

.search-center-box .search-payments-btn div[class^="col"] {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-center-box .search-payments-btn div[class^="col"] a {
  margin: 0 auto !important;
}

body.camera-opened .form-tab-box-sticker {
  position: relative;
}

a.cam-file-close {
  position: absolute;
  right: 18px;
  top: 10px;
  color: #fff !important;
  font-size: 22px;
  z-index: 99999;
  cursor: pointer;
}

.Toaster__manager-top {
  z-index: 99999999;
}

// Dashboard panel css 
.dashboard-wrapper {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 20px 20px;
  margin-top: 20px;
}

.activeUserNumber {
  font-size: 40px;
}

.activeUserText {
  font-size: 15px;
  padding-top: 16px;
  margin-left: 5px;
}
.dashboard-wrapper .activeuser p.activeUserNumber {
  margin: 0;
}

.dashboard-wrapper .activeuser p.activeUserText {
  margin-bottom: 0;
}

.dashboard-wrapper .activeuser p {
  color: #ed1b24;
  font-weight: 600;
}
.dashboard-wrapper .activeuser {
  background: #FFFFFF;
  // border: 1px solid rgba(221, 221, 221, 0.5);
  border-radius: 8px;
  padding: 0;
  height: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  text-transform: capitalize;
  margin-bottom: 0px;
}

.dashboard-wrapper .activeuser span.dot {
  top: 0;
  position: relative;
  margin-right: 10px !important;
  background: #36C04C;
  margin: 0;
  width: 16px;
  height: 16px;
}

.core-dashboard-charts .chart-title {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.chart-title {
  display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    flex-wrap: wrap;
}
.gap-3{
  gap: 15px;
}
.chart-title .btn-allign button {
    font-size: 12px;
    margin-top: 0;
    padding: 5px 8px;
}
.line-chart-header {
  display: flex;
  justify-content: center;
}

.core-dashboard-charts {
  position: relative;
  border: 1px solid #DDDDDD;
  border-radius: 8px;
  margin-bottom: 15px;
  padding: 40px 50px;
  margin-top: 0;
}
.core-dashboard-charts .chart-title h5 {
  flex: 0 0 30%;
}

.core-dashboard-charts .chart-title .nav-tabs {
  flex: 0 0 70%;
  margin: 0 !important;
  justify-content: flex-end;
  border: none !important;
}

.core-dashboard-charts .chart-title .tab-content {
  flex: 0 0 100%;
  margin-top: 40px;
}

.core-dashboard-charts .chart-title .tab-content .chart-wrapper {
  margin-top: 30px;
}

.titlered {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #ED1B24;
  margin: 0;
}
.titleflexbar button.crossicon img {
  width: 14px !important;
}
.core-dashboard-charts .chart-title .nav-tabs a {
  background: #ED1B24;
  border-radius: 8px;
  margin-left: 10px;
  margin-right: 0 !important;
  color: #ffffff;
  padding: 10px 30px;
}

.titleflexbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.titleflexbar button.crossicon {
  border: none;
  background: transparent;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
.pirchartbar {
  margin-bottom: 20px;
}

.pirchartbar .piechartbox {
  padding: 10px;
  background: #FFFFFF;
  border: none;
  border-radius: 0;
}

.pirchartbar .piechartbox .piechat-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.pirchartbar .piechartbox img {
  margin-right: 8px;
  width: 20px;
  object-fit: scale-down;
}
.headingName {
  font-size: 16px;
  font-weight: bold;
  margin: 5px;
}
.chart-row {
  display: flex;
  justify-content: space-between;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 8px;
  flex-wrap: wrap;
  padding: 20px;
}

.btn-allign button {
  margin-right: 10px;
  border: none;
  color: white;
  padding: 8px 14px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  width: 30px;
  margin-top: 14px;
  cursor: pointer;
  border: 1.5px solid;
  background: #ED1B24;
  border-color: #ED1B24;
}

.btn-allign button.outline {
  background: gray;
  border-color: gray;
  color: #ffffff;
}

.chart-column {
  width: 18%;
  position: relative;
}

.listingbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 0 0 50%;
  max-width: 50%;
  margin: 6px 0 10px 0px !important;
}

body .listingbar .dot {
  position: relative !important;
  top: 0 !important;
  margin: 0 !important;
  float: none !important;
}

body .listingbar p.displayText {
  margin: 0 0 0 5px !important;
}

.pirchartbar .piechartbox .piechart-display {
  flex-wrap: wrap;
}

.chart-row .chart-column:nth-child(2)::after {
  content: '';
  opacity: 0.4;
  border: 1px solid #DDDDDD;
  position: absolute;
  top: 50%;
  transform: translate(0px, -50%);
  width: 2px;
  height: 70%;
}

.chart-row .chart-column:nth-child(3)::after,
.chart-row .chart-column:nth-child(3)::before {
  content: '';
  opacity: 0.4;
  border: 1px solid #DDDDDD;
  position: absolute;
  top: 50%;
  transform: translate(0px, -50%);
  width: 2px;
  height: 70%;
}

.chart-row .chart-column:nth-child(4)::before {
  content: '';
  opacity: 0.4;
  border: 1px solid #DDDDDD;
  position: absolute;
  top: 50%;
  transform: translate(0px, -50%);
  width: 2px;
  height: 70%;
}

.chart-row .chart-column:nth-child(3)::before,
.chart-row .chart-column:nth-child(4)::before {
  right: -10px;
}

.chart-row .chart-column:nth-child(3)::after,
.chart-row .chart-column:nth-child(2)::after {
  left: -10px;
}

.chart-row .chart-column:nth-child(3)::after,
.chart-row .chart-column:nth-child(3)::before {
  content: '';
  opacity: 0.4;
  border: 1px solid #DDDDDD;
  position: absolute;
  top: 50%;
  transform: translate(0px, -50%);
  width: 2px;
  height: 70%;
}

.main-divv {
  display: flex;
  justify-content: space-between;
}

.dot1 {
  height: 10px;
  width: 10px;
  background-color: #ed1b24;
  border-radius: 50%;
  float: left;
  margin-top: 2px;
}

.dot1 p {
  margin-top: -3px !important;
  margin-bottom: 1rem;
  padding-left: 20px;
  font-size: 12px;
}

.dot2 {
  height: 10px;
  width: 10px;
  background-color: #211f1f;
  border-radius: 50%;
  float: left;
  margin-top: 2px;
}

.dot2 p {
  margin-top: -3px !important;
  margin-bottom: 1rem;
  padding-left: 20px;
  font-size: 12px;
}

.pirchartbar .piechartbox .piechat-title h5 {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  color: #000000;
}

.pirchartbar .piechartbox .piechart-display {
  display: flex;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
  color: #000000;
  margin-top: 10px;
  // border: 1px solid black;
  // height: 20px;
}

.pirchartbar .piechartbox .chartnumber {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  // display: inline-block;
}

.displayText {
  font-size: 10px;
  line-height: 14px;
  color: #000;
}

.percentageChange {
  color: #f40820 !important;
}

.pirchartbar .piechartbox .chartnumber h3 {
  margin: 0 5px -1px 0;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 18px;
  color: #000000;
}
.line-chart-header h6 span.line {
  width: 40px;
  height: 3px;
  display: inline-block;
  background: gray;
  border-radius: 20px;
  margin-right: 10px;
  position: relative;
}

.alignCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.line-chart-header h6 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 16px;
}

.line-chart-header h6.member span.line , .line-chart-header h6.member span.line::after {
  background: #c92525;
}

.line-chart-header h6 span.line::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  background: gray;
  width: 15px;
  height: 10px;
  transform: translate(-50%, -50%);
}
.pirchartbar .piechartbox .chartnumber h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  display: flex;
  align-items: center;
  color: #36C04C;
  margin: 0;
}

.pirchartbar .piechartbox .chartnumber h4 img {
  margin-right: 5px;
  width: 10px;
}
.thepiechart .chart-wrapper canvas {
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin: 0 auto !important;
  height: auto !important;
  width: 100% !important;
}

.chartjs-tooltip {
  opacity: 1 !important;
  position: relative !important;
  left: inherit !important;
  display: none !important;
  top: inherit !important;
}

.donughatChat {
  position: relative;
}

.donughatChat span.noResults {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
}
body iframe {
    display: none !important;
}


body #root iframe {
    // display: block !important;
}

@media (max-width: 1499px){
  .chart-column{
    width: 23%;
  }
}
@media (max-width: 1299px){
  .chart-column{
    width: 33.33%;
  }
  .chart-row {
    justify-content: flex-start;
}
}

@media only screen and (max-width: 991px) {
  .auth-logo {
    width: 150px;
  }
  span {
    line-height: 12px;
    white-space: wrap;
    text-align: center;
  }

  .auth-content-area,
  .landing-reviews-wrapper {
    padding: 35px 15px;
    margin-top: 0px;
    width: 100%;
  }

  .auth-content-box {
    width: 100%;
  }

  .auth-content-box h1 {
    font-size: 26px;
    margin-bottom: 20px;
  }

  header.header-top {
    display: flex;
  }

  .sidebar .logo-timer {
    display: none;
  }

  ul.list-sidebar.bg-defoult {
    padding-top: 0 !important;
  }

  .sidebar {
    padding-top: 60px;
  }

  .student-listing-wrapper.common-wrapper {
    margin-top: -10px;
  }

  .student-listing-wrapper.common-wrapper .top-header .right-flex-header.student-list-cons {
    width: 100%;
  }

  .student-listing-wrapper.common-wrapper .top-header .right-flex-header {
    width: 100%;
    float: unset;
    display: flex;
    margin-top: 15px;
  }

  .student-listing-wrapper.common-wrapper .top-header .right-flex-header .btns-head {
    width: auto;
  }

  .student-listing-wrapper.common-wrapper .top-header .right-flex-header .btns-head a {
    margin-left: 0;
  }

  .student-listing-wrapper.common-wrapper .flex-header.top-header h4 {
    width: 100%;
  }

  .form-top-btns-bar {
    margin: 15px 0;
  }

  .less-pad-l {
    padding-left: 15px !important;
  }

  .less-pad-r {
    padding-right: 15px !important;
  }

  .orderDetail {
    display: flex;
    justify-content: start;
  }

  .pending-payment-header h2,
  .pending-payment-header .btn-head {
    width: 100%;
  }

  .pending-payment-header .btn-head {
    text-align: right;
    display: flex;
    justify-content: flex-end;
  }

  .pending-payment-header .btn-head .btn-common-img {
    margin-left: 0;
    width: 80px !important;
    display: block;
    text-align: center;
  }    

  // .order-header {
  //   display: flex;
  //   justify-content: start;
  // }

  .landing-reviews-wrapper {
    justify-content: center;
  }

 

  .auth-logo {
    margin-top: 15px;
  }

  .form-tab-box-sticker {
    position: relative;
    top: 0;
    margin-bottom: 15px;
  }

  .history-tab-table-wrapper {
    margin-inline: 0 !important;
  }

  .row.search-btn {
    margin: 15px 0;
  }

}
.row{
  row-gap: 25px !important;
}

@media only screen and (max-width: 767px) {
  
.chart-column {
  width: 100%;
}
  .student-listing-wrapper.common-wrapper .top-header .right-flex-header {
    margin-left: 0 !important;
    margin-right: auto !important;
    display: block !important;
    text-align: left !important;
  }

  .student-listing-wrapper.common-wrapper .top-header .right-flex-header .btns-head {
    width: auto;
    margin-left: 0;
  }

  .student-listing-wrapper.common-wrapper .top-header .right-flex-header .search-head {
    margin-right: 15px;
  }

  .expand-btn {
    position: absolute;
    margin-left: 20px;
  }

  .flex-header .btn-common-img {
    width: auto;
    min-width: 150px;
  }

  .flex-header .btn-common-img span {
    display: inline-block;
  }

  .student-list-table .btn-common-img {
    width: auto;
  }

  .history-tab-table-wrapper .row .col-lg-1,
  .history-tab-table-wrapper .row .col-lg-2,
  .history-tab-table-wrapper .row .col-lg-3,
  .history-tab-table-wrapper .row .col-lg-4,
  .history-tab-table-wrapper .row .col-lg-5,
  .history-tab-table-wrapper .row .col-lg-6,
  .history-tab-table-wrapper .row .col-lg-7,
  .history-tab-table-wrapper .row .col-lg-8,
  .history-tab-table-wrapper .row .col-lg-9,
  .history-tab-table-wrapper .row .col-lg-10,
  .history-tab-table-wrapper .row .col-lg-11,
  .history-tab-table-wrapper .row .col-lg-12,
  .history-tab-table-wrapper .row .col-md-1,
  .history-tab-table-wrapper .row .col-md-2,
  .history-tab-table-wrapper .row .col-md-3,
  .history-tab-table-wrapper .row .col-md-4,
  .history-tab-table-wrapper .row .col-md-5,
  .history-tab-table-wrapper .row .col-md-6,
  .history-tab-table-wrapper .row .col-md-7,
  .history-tab-table-wrapper .row .col-md-8,
  .history-tab-table-wrapper .row .col-md-9,
  .history-tab-table-wrapper .row .col-md-10,
  .history-tab-table-wrapper .row .col-md-11,
  .history-tab-table-wrapper .row .col-md-12 {
    padding: 0;
    margin-bottom: 0 !important;
  }

  .radiocustom {
    margin-bottom: 0 !important;
  }

  .rimg-viewerpicker-img {
    height: 200px;
    width: 200px;
  }

  .form-top-btns-bar ul {
    flex-wrap: wrap;
    margin-top: -15px;
  }

  .form-top-btns-bar ul li {
    flex-grow: 1;
    min-width: 30%;
    margin: 5px;
  }

  .outline-box .same-line-label {
    flex-direction: column;
  }

  .outline-box .same-line-label label {
    margin-bottom: 15px;
    padding-left: 0;
  }

  .outline-box .same-line-label .qtys-con {
    margin: 0 auto;
  }

  .search-center-box .search-payments-btn div[class^="col"] a {
    width: 120px;
  }

  .dashboard-wrapper .activeuser {
    font-size: 18px;
  }

  .btn-allign button {
    width: 90%;
    margin-bottom: 10px;
  }

  .chart-column {  
    width: 100%;
  }

  .chart-row {
    flex-direction: column;
  }

  .core-dashboard-charts {
    padding: 20px;
  }

  .activeuser.btn {
    width: 100%;
  }

}

@media only screen and (max-width: 500px) {
  .student-listing-wrapper.common-wrapper .top-header .right-flex-header .search-head {
    width: 100%;
    margin-bottom: 20px;
  }
}