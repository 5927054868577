// If you want to override variables do it here
@import "variables";

// Import styles
// @import "~@coreui/coreui/scss/coreui.scss";

@import 'bootstrap/scss/bootstrap.scss';

// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";

body{
    font-family: 'Montserrat', sans-serif;
}
span {
  line-height: 12px;
  white-space: nowrap;
  text-align: center;
}
.order-header {
    display: flex;
    justify-content: start;
  }

  .order-data {
    font-size: 13px;
    font-family: 'Proxima-Nova-Regular' ;
  }

  .filterTab {
    font-size: 20px;
    font-weight: 800;
    color: #ED1B24;
    font-family: Proxima-Nova-Bold;
    margin-left: 22px !important;
  }