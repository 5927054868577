@import "./student.dashboard";
// @import "./from_other_website";
@import "./landing-page";

// Here you can add other styles
.help-block {
  color: #ED1B24 !important;
}

.customDatePickerWidth,
.customDatePickerWidth>div.react-datepicker-wrapper,
.customDatePickerWidth>div>div.react-datepicker__input-container .customDatePickerWidth>div>div.react-datepicker__input-container input {
  width: inherit !important;
}

.cursor-pointer {
  cursor: pointer;
}

.react-autosuggest__suggestions-container--open {
  min-width: 10rem;
  padding: 0;
  margin: .125rem 0 0;
  font-size: .875rem;
  color: #23282c;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #c8ced3;
  border-radius: .25rem;

  ul {
    padding: 0px;
    margin: 0px;
  }

  li {
    list-style: none;
  }
}

.module-list-add-course {
  border: 1px solid #c8ced3;
  padding: 10px;
  max-height: 200px;
  overflow: scroll;
  margin-top: 20px;
  border-radius: .25rem;
}

.color-#ED1B24 {
  color: #ED1B24;
}

.color-blue {
  color: #2d6baa;
}

.custom-checkbox {
  color: black;
  font-size: 27px;
  float: right;
  font-weight: 600;
  cursor: pointer;
}

.dashborad-section {
  .sidebar {
    .nav-label {
      display: initial;
    }
  }
}

.disable-nav-student {
  opacity: 0.7;
  cursor: not-allowed;
  pointer-events: none;
}

// registrationuserbar css start here
.registrationuserbar {
  position: relative;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0 2px 0 0 #f2f1f1;
  border-radius: 8px;
  height: 37%;
  // border-bottom: 8px solid #ED1B24;
}

.btnUpload {
  font-family: 'Proxima-Nova-Regular';
  font-size: 12px;
  height: 32px;
  width: 164px;
  background: #EFEFEF;
  border: 1px solid #AFAFAF;
  border-radius: 4px;
  margin-left: 10px;
}

.displayBox {
  display: flex;
  justify-content: space-between;
}

.addMemberHeadings {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 30px;
}

.registrationuserbar .userimg {
  border-radius: 50%;
  width: 250px;
  height: 250px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}

.registrationuserbar .userimg input#file {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
}

.editmember-ryt {
  padding: 30px;
  // border-bottom: 8px solid #ED1B24;
}

.border-bottom-#ED1B24 {
  border-bottom: 8px solid #ED1B24;
}

.display-flex {
  display: flex;
  justify-content: space-between;
}

.variantTopBlock {
  margin-top: 10px;
  border: 2px solid #d4c7c7;
}

.variantBlock {
  display: flex;
  align-items: center;
  // height: 100%;
  // width: 100%;
  padding: 10px;
}

.filtersBlock {
  border: 2px solid #d4c7c7;
  height: 100%;
  margin: 0px 40px 10px 20px;
}

.filterName {
  margin: 5px 0px 0px 12px;
}

.filterOptions {
  margin: 10px;
}


.editmember-ryt .form-modal-div {
  margin-bottom: 30px;
}

.editmember-ryt .form-modal-div input {
  height: 34px;
  border: 1px solid #858C94;
  border-radius: 8px;
}

.editmember-ryt .form-modal-div input[type=radio] {
  height: auto;
  position: relative;
  width: 20px;
  height: 20px;
}

.addressSpace {
  margin-bottom: 10px !important;
}

.editmember-ryt .form-modal-div .form-check {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.editmember-ryt .form-modal-div .form-check input {
  margin-right: 10px;
}

.gender-radiobar .radiowrapper {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 13px;
  -webkit-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.gender-radiobar .radiowrapper input {
  position: absolute;
  opacity: 0;
  display: none;
  cursor: pointer;
}

/* Create a custom radio button */
.gender-radiobar .checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid #ED1B24;
}

/* When the radio button is checked, add a blue background */
.gender-radiobar .radiowrapper input:checked~.checkmark {
  background-color: #ffffff;
  border-width: 4px;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.gender-radiobar .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radiocustom {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: 38px;
}

.radiocustom .form-modal-div {
  margin: 0;
}

.radiocustom .radiocustom-in {
  margin-right: 10px;
  margin-bottom: 0px;
}

.radiocustom .radiocustom-in:last-child {
  margin-right: 0;
}

.membership-table {
  padding: 20px !important;
  background: #E3E3E3 !important;
  /* box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.07); */
  border-radius: 8px !important;
  margin: 10px !important;
}

.search-space {
  margin: 14px 10px 0px 10px;
}

.membership-border {
  border: 1px solid #ED1B24;
  color: #ED1B24 !important;
  padding: 2px !important;
}

.pt-border {
  color: #ED1B24 !important;
  padding: 2px !important;
}

.form-modal-div h6, .form-modal-div label {
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  color: #09101D;
  font-family: 'Proxima-Nova-Regular';
}

.form-modal-div p {
  font-size: 12px;
  display: flex;
  margin-bottom: 5px;
  align-items: center;
}

.form-modal-div h6 span , .form-modal-div label span{
  color: #ED1B24;
}

.tersmconditionbar .row.p-4 {
  padding: 30px 0 !important;
}

.footer {
  display: none;
}

body {
  font-family: 'Proxima-Nova-Regular' !important;
}

/* The container */
.customcheckbox {
  display: block;
  font-family: 'Proxima-Nova-Regular';
  position: relative;
  padding-left: 35px;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  user-select: none;
  color: #222446;
}

/* Hide the browser's default checkbox */
.customcheckbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  display: none;
}

/* Create a custom checkbox */
.customcheckbox .checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 1px solid #ED1B24;
}

.border-bottom-red {
  border-bottom: 8px solid #ED1B24;
  height: 100%;
}

/* When the checkbox is checked, add a blue background */
.customcheckbox input:checked~.checkmark {
  background-color: #ED1B24;
}

/* Create the checkmark/indicator (hidden when not checked) */
.customcheckbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.btncenterbar .btn-common-img {
  padding: 7px 20px;
  width: auto;
  height: auto;
  background-color: #ED1B24;
  border: 1px solid #ED1B24;
  font-size: 16px;
  transition: .3s ease;
  color: #ffffff !important;
}

.btncenterbar .btn-common-img:hover {
  background-color: #ffffff;
  color: #ED1B24 !important;
}

/* Show the checkmark when checked */
.customcheckbox input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.customcheckbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


.tersmconditionbar h6 {
  font-size: 18px;
}

.tersmconditionbar ul {
  padding: 0 0 0 20px;
}

.tersmconditionbar ul li {
  font-size: 14px;
}

.btncenterbar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-modal-div input[type='date']::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url("/img/calender.png") no-repeat;
  width: 20px;
  height: 20px;
  top: 2px;
  right: -10px;
  position: relative;
  border-width: thin;
}

.sidebar li a.active,
.sidebar li a.active span {
  background: #ED1B24 !important;
  border: none !important;
  color: #ffffff !important;
}

.history-tab-table-wrapper {
  padding: 20px !important;
  background: #FFFFFF;
  box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
}

.pending-payment-header {
  display: flex;
  justify-content: space-between;
}

.registrationuserbar .userimg img {
  object-fit: cover;
}

.mt-4.container-fluid {
  margin: 0 !important;
}

.mt-4.container-fluid iframe {
  display: none;
}

.new-student-list-mt {
  margin-top: 20px !important;
}

.new-package-list-mt {
  margin-top: 22px !important;
}











.mandatory_red_class {
  color: #ED1B24;
  float: right;
  margin-left: 2px;
}

.batch-videos {
  height: 260px;
  overflow: hidden;
  transition: .5s;
}


.batch-videos~.see-more img {
  transform: rotate(90deg);
}

.batch-videos.active~.see-more img {
  transform: rotate(-90deg);
}

.batch-videos.active {
  height: auto;
  transition: .5s;
}

// changes by dangi while bug fixing
.css-11unzgr {
  max-height: 75px !important;
}

.class_files_width {
  max-width: 100px !important;
}


// infinite scroll css

// .scrollalble-drop-btn {
//     background: #6ec6e5 !important;
//     color: #fff !important;
//     font-size: 20px !important;
//     height: 35px !important;
//     position: absolute !important;
//     right: 0px !important;
//     top: 0 !important;
//     display: flex !important;
//     justify-content: center !important;
//     align-items: center !important;
// }
// .item_dropdown_custom{
//     display: block !important;
//     min-width: 98%;
//     left: 3%;
// }


//message
.fullscreenblue {
  width: 100%;
  float: left;
  height: 100vh;
  // background: #ED1B24;
  display: flex;
  background-image: url("/img/verify-image.jpg");
  justify-content: center;
  align-items: center;
  background-size: cover;
}

.message-wrapper {
  max-width: 500px;
  text-align: center;
}

.message-wrapper img {
  margin-bottom: 50px;
}

.message-wrapper-inner {
  background: #FFFFFF;
  box-shadow: 0 2px 10px 0 #002346;
  border-radius: 36px;
  width: 100%;
  float: left;
  text-align: center;
  padding: 15px;
  min-height: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.message-wrapper-inner h1 {
  font-family: Proxima-Nova-Bold;
  font-size: 40px;
  color: black;
  letter-spacing: -0.67px;
  text-align: center;
  margin-bottom: 0px;
}

.message-wrapper-inner p {
  max-width: 250px;
  margin: 20px auto;
  text-align: center;
  font-size: 14px;
  color: #0F0F0F;
  letter-spacing: -0.23px;
  line-height: 18px;
  margin-bottom: 30px;
}

.message-wrapper-inner a {
  background: #ED1B24;
  border-radius: 25px;
  color: white;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: initial;
  margin: 0 auto;
  font-size: 13px;
  padding: 0px 20px;
}

.bottom-message {
  text-align: center;
  margin-top: 40px;
  width: 100%;
  float: left;
}

.bottom-message img {
  width: 95px;
  margin-bottom: 10px;
}

.bottom-message p {
  font-size: 8px;
  color: white;
}

// pagination react
//   .pagination {
//     margin: 15px auto;
//     display: flex;
//     list-style: none;
//     outline: none;
//     float: right;
//   }
//   .pagination > .active > a{
//     background-color: transparent;
//     border-color: #003366;
//     outline: none;
//     color: #00B4F3;
//   }
//   .pagination > li > a{
//     border: unset;
//     padding: 5px 10px;
//     outline: none;
//     border: 1px solid #003366;
//     // border-right: 1px solid #003366;
//     cursor: pointer;
//   }
//   .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
//     background-color: transparent ;
//     border-color: #003366;
//     outline: none ;
//     color: #00B4F3;
//   }
//   .pagination > li > a, .pagination > li > span{
//     color: #003366;
//   }
//   .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
//     border-radius: unset;
//   }
//   .pagination li:last-child a {
//     border-right: unset !important;
// }

.pagination-student {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
  float: right;
}

.pagination-student>.active>a {
  background-color: #ED1B24;
  ;
  border-color: #ED1B24;
  ;
  color: #00B4F3;
}

.pagination-student>li>a {
  border: 1px solid #e4e4e4;
  padding: 6px 12px;
  outline: none;
  cursor: pointer;
  border-radius: unset !important;
}

.pagination-student>li:first-child>a {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.pagination-student>li:last-child>a {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.pagination-student>.active>a,
.pagination-student>.active>span,
.pagination-student>.active>a:hover,
.pagination-student>.active>span:hover,
.pagination-student>.active>a:focus,
.pagination-student>.active>span:focus {
  background-color: #ED1B24;
  ;
  border-color: #ED1B24;
  ;
  outline: none;
  color: #fff;
}

.pagination-student>li>a,
.pagination-student>li>span {
  color: #003366;
}

.pagination-student>li:first-child>a,
.pagination-student>li:first-child>span,
.pagination-student>li:last-child>a,
.pagination-student>li:last-child>span {
  border-radius: 0.25rem
}

.class-time-picker-only .react-datepicker__triangle {
  left: 50% !important;
}

// SCustom CSS.....
.dashboard_outer_wrpr {
  width: 100%;
  float: left;
  margin-top: 30px;
}

h3.batches_head {
  color: black;
  font-size: 25px;
  font-family: Proxima-Nova-Bold;
}

.from_date {
  // border-bottom: 1px solid #0000005e;
  color: #0000005e;
  // width: 150px;
}

.to_date {
  // border-bottom: 1px solid #0000005e;
  color: #0000005e;
  // width: 150px;
}

.from_date input,
.to_date input {
  height: 45px;
}

.date_picker_head {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.batches_content h2 {
  color: black;
  font-size: 14px;
  font-family: Proxima-Nova-Bold;
  text-transform: uppercase;
}

.batches_content {
  background-color: #ffffff4d;
  box-shadow: 0px 2px 25px #c6c6c694;
  padding: 20px 0px 20px 20px;
  border-radius: 10px;
  margin-top: 15px;
  transition: all 0.3s ease;
}

.counter_span span {
  font-size: 19px;
  color: #009DDD;
  font-family: Proxima-Nova-Bold;
}

.btn.btn_view {
  color: white;
  background-color: #ED1B24;
  font-size: 15px;
  padding: 8px 10px;
  border-radius: 8px;
  font-family: 'Proxima-Nova-Regular';
}

.btn.btn_view:hover {
  color: white;
}

.counter_span {
  margin-bottom: 20px;
  margin-top: 8px;
}

.counter_span.counter_span2 {
  margin-bottom: 0px;
  margin-top: 8px;
}

h3.batches_head.btch_head {
  margin-top: 20px;
}

.batches_content-outer.batches_content {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 20px 20px 20px 20px;
}

.batches_content_right .btn_view {
  margin-top: 20px;
}

.w-95 .batches_content {
  padding: 30px 25px 25px 25px;
  transition: all 0.3s ease;
}


// Ongoing batches css
.ongoing_outer_wrpr {
  width: 100%;
  float: left;
  margin-top: 0px;
}

.container_outer {
  width: 100%;
  float: left;
}

.search_down {
  // display: flex;
  // flex-direction: row;
  // justify-content: space-between;
  // align-items: center;
  position: relative;
}

.search_down input {
  max-width: 535px;
  border: 0px;
  // position: absolute;
  padding-left: 35px;
}

.search_down input::placeholder {
  font-size: 13px;
}

.btn.btn_view.down_btn {
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 0.25rem;
}

.table_outer {
  margin-top: 40px;
}

.btn.btn_join {
  background-color: #FF5A5F;
  color: white;
  padding: 1px 15px;
  font-size: 15px;
}

thead.head_color {
  background-color: #e3e3e354;
  font-size: 14px;
  border-radius: 0.25rem;
  text-align: center;
  color: #00000061;
  font-weight: 200;
  font-family: Proxima-Nova-Regular;
}

.table_outer table td {
  font-size: 14px;
  padding: 18px 10px;
  font-family: Proxima-Nova-Regular;
  border-right: 0.5px solid #0000000f;
  text-align: center;
}

.table_outer table td:last-child {
  border-right: 0px solid #00000033;
}

.table_outer table tbody {
  background-color: #ffffff7a;
}

.featuredImg {
  font-weight: bold;
  font-family: 'Proxima-Nova-Regular';
}

.table_outer table thead tr th {
  border-bottom: 0px !important;
}

.table_outer table thead tr:first-child {
  padding-bottom: 0px;
}

.table_outer table thead tr th:first-child {
  border-radius: 12px 0px 0px 0px;
}

.table_outer table thead tr th:last-child {
  border-radius: 0px 12px 0px 0px;
}

.join_date {
  color: #00000061;
  font-size: 13px;
  margin-top: 5px;
}

td.copyclass i {
  font-size: 12px;
  margin-left: 8px;
  cursor: pointer;
}

td.copyclasss i {
  font-size: 24px;
  margin-left: 8px;
  cursor: pointer;
}

td.infoclass i {
  font-size: 17px;
  color: black;
  cursor: pointer;
  border: 0px;
  outline: none;
}

.search_down i {
  // position: relative;
  position: absolute;
  left: 12px;
  z-index: 99;
  color: #00000069;
  top: 12px;
}

.drop_popup {
  position: absolute;
  left: -153px;
  top: 0px;
}

.drop_popup .dropdown-menu.show {
  display: block;
  left: 35px !important;
  border: 0px;
  box-shadow: 0px 0px 50px 5000px #00000040;
}

.dropitemmain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 230px;
}

.infoclass .dropdown button:focus-visible {
  outline: none;
  border: 0px;
}

.infoclass .dropdown button:focus {
  outline: none;
  border: 0px;
}

.btn.text-center.viewdetails {
  border: 1px solid #009DDD;
  display: flex;
  width: 60%;
  justify-content: center;
  margin: auto;
  align-items: center;
  color: #009DDD;
  height: 28px;
  font-size: 12px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 6px 1px #009ddd36;
}

.dropitemleft p {
  font-size: 15px;
  color: #30303085;
  font-family: 'Proxima-Nova-Semi-bold';
}

.dropitemright p {
  font-size: 15px;
  font-family: 'Proxima-Nova-Regular';
  color: black;
  text-align: right;
}

.drop_popup .dropdown-item:active {
  background-color: transparent;
}

.drop_popup .dropdown-item:hover {
  background-color: transparent;
}

.accodian_main {
  width: 100%;
  float: left;
}

thead.accord_head {
  background-color: white;
}

.accodian_main .accordion .card-header:after {
  font-family: 'FontAwesome';
  content: "\f068";
  float: right;
}

.accodian_main .accordion .card-header.collapsed:after {
  /* symbol for "collapsed" panels */
  content: "\f067";
}

.accodian_main .table {
  margin-bottom: 0px;
}

.accodian_main .table {
  margin-bottom: 0px;
}

thead.accord_head tr th {
  font-size: 14px;
  color: #00000075;
  text-align: center;
}

.accodian_main .accordion .card {
  background-color: white;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
}

.accodian_main .accordion .card .card-header {
  background-color: white;
  border-bottom: 0px solid rgba(0, 0, 0, 0.125);
  padding-top: 0px;
  padding-bottom: 0px;
}

.accodian_main table thead tr th:first-child {
  border-radius: 12px 0px 0px 0px;
}

.accodian_main table thead tr th:last-child {
  border-radius: 0px 12px 0px 0px;
}

.acord_datahead {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.datahead {
  max-width: 160px;
  width: 100%;
  align-items: center;
  border-right: 1px solid #0000002e;
}

.datahead:last-child {
  border-right: 0px solid #0000002e;
}

.accodian_main .accordion .card-header:after {
  font-family: 'FontAwesome';
  content: "\f068";
  float: right;
  top: -40px;
  right: 14px;
  position: relative;
}

.datahead p {
  margin-bottom: 0px;
  padding: 15px 0px 0px 0px;
}

.accodian_main .accordion .card .card-body {
  padding-top: 0px;
  padding-bottom: 0px;
}

// accordian table

// Table
.accodian_main table {
  width: 100%;

  th {
    text-align: left;
    border-bottom: 1px solid #ccc;
  }

  th,
  td {
    padding: .4em;
  }
}

// fold table 
table.fold-table {
  >tbody {

    // view segment
    >tr.view {

      td,
      th {
        cursor: pointer;
      }

      &:nth-child(4n-1) {
        background: #eee;
      }

      &:hover {
        background: #000;
      }

      &.open {
        background: tomato;
        color: white;

        td:first-child,
        th:first-child {
          &:before {
            transform: rotate(-180deg);
            color: #333;
          }
        }
      }
    }

    // fold segment
    >tr.fold {
      display: none;

      &.open {
        display: table-row;
      }
    }
  }
}


table.fold-table thead {
  background: white;
}

table.fold-table thead tr th {
  text-align: center;
  font-size: 15px;
  color: #00000059;
  font-family: 'Proxima-Nova-Regular';
  font-weight: 600;
  padding: 12px;
}

.accodian_main tr.view.open td {
  text-align: center;
  color: black;
  border-bottom: 1px solid #0000001a;
  border-right: 1px solid #0000001a;
}

.accodian_main tr.view td {
  text-align: center;
  color: black;
  border-bottom: 1px solid #0000001a;
  border-right: 1px solid #0000001a;
}

.accodian_main tr.view.open td:last-child {
  border-right: 0px solid #0000001a;
}

.accodian_main tr.view td:last-child {
  border-right: 0px solid #0000001a;
}

table.fold-table>tbody>tr.view td:first-child:before,
table.fold-table>tbody>tr.view th:last-child::after {
  position: absolute;
  content: " " !important;

}

.accodian_main table.fold-table>tbody>tr.view td:last-child::after {
  position: absolute;
  top: 50%;
  left: 60px;
  width: 9px;
  height: 16px;
  margin-top: -8px;
  font: 16px fontawesome;
  color: black;
  content: "\f067" !important;
  transition: all .3s ease;
}

.accodian_main table.fold-table>tbody>tr.view.open td:last-child::after {
  position: absolute;
  top: 50%;
  left: 60px;
  width: 9px;
  height: 16px;
  margin-top: -8px;
  font: 16px fontawesome;
  color: black;
  content: "\f068" !important;
  transition: all .3s ease;
}

.fold-content {
  padding: 0px;
}

table.fold-table>tbody>tr.view td:last-child::after {
  position: relative !important;
  padding-left: 20px;
}

table.fold-table>tbody>tr.view:hover {
  background-color: white !important;
}

table.fold-table>tbody>tr.view.open {
  background: white !important;
  color: white;
}

td.fold_open_td {
  padding: 0px !important;
}

.fold-content>table {
  border: 0px;
}

.accodian_main .fold-content table thead tr th:first-child {
  border-radius: 0px 0px 0px 0px;
}

.accodian_main .fold-content table thead tr th:last-child {
  border-radius: 0px 0px 0px 0px;
}

.fold-content table thead tr th {
  text-align: center !important;
  border-right: 1px solid #00000021;
  font-size: 13px;
  font-weight: 100;
  padding: 0.4rem;
}

.fold-content table thead tr th:last-child {
  border-right: 0px solid #00000021;
}

.fold-content table tbody tr td {
  text-align: center;
  font-size: 13px;
  border-right: 1px solid #0000001a;
}

.fold-content table tbody tr td:last-child {
  border-right: 0px solid #0000001a;
}

tr.view {
  background: white !important;
  text-align: center;
}

tr.fold.ope,
tr.fold {
  background-color: white;
  text-align: center;
  border-bottom: 1px solid #0000001c;
}

tr.fold.open td,
tr.fold td {
  border-right: 1px solid #0000001c;
  font-size: 14px;
  color: #00000061;
  font-weight: 600;
  padding: 10px;
}

tr.fold.subfold.open td,
tr.fold.subfold td {
  font-size: 13px;
  color: black;
  font-weight: 100;
}

tr.fold.subfold.open,
tr.fold.subfold {
  background-color: transparent;
  border-bottom: 0px;
}

tr.fold.open td:last-child,
tr.fold td:last-child {
  border-right: 0px solid #0000001c;
}

// accordian table end
// Ongoing batches css
// responsive css start
@media (max-width: 1280px) {

  .search_down input {
    max-width: 580px;
  }

}

@media (max-width: 998px) {}

@media (max-width: 1024px) {

  .search_down input {
    max-width: 450px;
  }
}

@media (min-width: 768px) and (max-width:769px) {
  .search_down {
    margin-top: 15px;
  }

}

@media (max-width: 767px) {

  .batches_head {
    margin-bottom: 20px;
  }

  .table_outer {
    overflow: scroll;
    width: 100%;
  }

  .search_down input {
    max-width: 550px;

  }

  .search_down {
    margin-top: 25px;
  }

  .table {
    width: 750px;
    margin-bottom: 1rem;
    color: #212529;
  }

}

@media (max-width: 375px) {
  .search_down input {
    max-width: 190px;
  }
}

.white-color {
  color: white;
}

.white-color:hover {
  color: white;
}

.accodian_main table.fee-details>tbody>tr.view td:last-child::after,
.accodian_main table.fold-table>tbody>tr.view.open td:last-child::after {
  // content: "" !important;
  top: unset;
  left: unset;
  width: unset;
  height: 16px;
}

table.sticky-header thead {
  background: white;
  margin: 5px 5px 5px 5px;
  position: sticky;
  top: 4.7em;
  z-index: 9999;
}

.same-line-form {
  display: flex;
}

.same-line-form .form-modal-div {
  width: auto;
  margin-right: 50px;
}

.same-line-form .form-modal-div:last-child {
  margin-right: 0;
}

// SCustom CSS End......
@media (max-width: 767px) {
  .history-tab-table-wrapper {
    margin-bottom: 20px;
    height: auto;
  }

  .editmember-ryt .form-modal-div {
    margin-bottom: 10px;
  }

  .history-tab-table-wrapper .row {
    margin: 0;
  }

  .tersmconditionbar .p-4 {
    padding: 0 0 !important;
    margin-bottom: 20px;
  }

  .customcheckbox {
    font-size: 14px;
  }

  .history-tab-table-wrapper .row .col-lg-6,
  .history-tab-table-wrapper .row .col-lg-12 {
    padding: 0;
    margin-bottom: 12px;
  }
}